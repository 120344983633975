import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import queryString from 'query-string';
import {
    ActionCreatorType,
    createUpdateAction,
    createUpdateReducer,
} from '@core/store/actions';
import { createType } from '../core';
import {
    IBaseHandler,
    IFetchHandler,
    IFetchUpdateHandler,
    SimpleSelector,
} from '../props';
import {
    callApiSaga,
    CLEAR_FETCH_STATE,
    handleCommonApiErrorsSafe,
    logNetworkError,
    NetworkOptions,
    NetworkRequestStat,
    TApiMethodResponse,
} from './common';
import { ApiListResponse } from '../../models/api';
import { equals } from '../../utils/equals';

export interface IApiListPagingProps {
    offset?: number;
    limit?: number;
}

export type ApiListSortDirection = 'asc' | 'desc';

export interface IApiListSortProps {
    field?: string;
    dir?: ApiListSortDirection;
}

export interface ApiListFilterOptionsBase {
    values?: string[];
    start?: string;
    end?: string;
    included?: string;
    excluded?: string;
}

export interface ApiListFilterOptions extends ApiListFilterOptionsBase {
    field: string;
}

export interface ApiListAggregationOptions {
    field: string;
}

export interface IApiListQueryProps {
    paging?: IApiListPagingProps;
    sort?: IApiListSortProps;
    search?: string;
    filter?: ApiListFilterOptions[];
    aggregation?: ApiListAggregationOptions[];

    resolveUrl(path: string): string;
}

export interface EditableApiListState<TModel = any> {
    offset: number;
    limit: number;
    sortField?: string;
    sortDirection?: ApiListSortDirection;
    search?: string;
    filter?: ApiListFilterOptions[];
    aggregation?: IListHandlerAggregationOption<TModel>[];
}

export type RemoteApiListState = EditableApiListState;

export interface ApiListState<TModel> extends EditableApiListState<TModel> {
    data: TModel[];
    offset: number;

    totalCountResponse?: number;
    aggregationResponse?: Partial<TModel>;

    fetching?: boolean;
    fetchingExtra?: boolean;
    ready?: boolean;
    completed?: boolean;
}

/**
 * Реализует доступ к удаленному списку данных, поддерживает постраничную или бесконечную загрузку данных, фильтрацию, сортировку и поиск.
 *
 * Создается с помощью функции {@link listApiHandler}
 *
 * @category Duck Handlers
 *
 * @see {@link listApiHandler}
 * @see {@link useListApiHandler}
 * @see {@link useIsApiFetching}
 */
export interface IListApiHandler<TModel, TMode extends ListHandlerMode = ListHandlerMode.Continues> extends IBaseHandler, IFetchHandler, IFetchUpdateHandler {
    /**
     * Режим работы хендлера.
     */
    mode: TMode;
    /**
     * @event
     */
    FETCH: string;
    /**
     * @event
     */
    FETCH_NEXT: string;
    /**
     * @event
     */
    FETCH_PAGE: string;
    /**
     * Срабатывает при успешной загрузке данных.
     * @event
     */
    FETCH_DONE: string;
    /**
     * Срабатывает при достижении конца списка с данными.
     * Применимо только для {@link ListHandlerMode.Continues}
     * @event
     */
    ALL_DONE: string;

    /**
     * Загружает следующую порцию данных.
     */
    fetchNext: ActionCreatorType<any>;
    /**
     * Загружает указанную страницу данных.
     * @param paging
     * @returns
     */
    fetchPage: (paging: IApiListPagingProps) => any;
    /**
     * Обновление конфигурации загрузки данных.
     * При значимом изменении, данные будут автоматически перезагружены.
     * @param config
     * @returns
     */
    updateConfig: (config: Partial<EditableApiListState>) => any;

    /**
     * Возвращает флаг, что все данные были полностью загружены.
     * Применимо только для режима бесконечной загрузке {@link ListHandlerMode.Continues}
     * @group Selectors
     */
    isCompleted: SimpleSelector<boolean|undefined>;
    /**
     * Возвращает флаг, что данные загружаются.
     * @group Selectors
     */
    isFetching: SimpleSelector<boolean|undefined>;
    /**
     * Возвращает флаг, что данные следующей порции загружаются в данный момент.
     * Применимо только для режима бесконечной загрузке {@link ListHandlerMode.Continues}
     * @group Selectors
     */
    isFetchingExtra: SimpleSelector<boolean|undefined>;

    /**
     * Возвращает данные.
     * @group Selectors
     */
    selector: SimpleSelector<TModel[]>;
    /**
     * Возвращает состояние текущей страницы.
     * @group Selectors
     */
    pageSelector: SimpleSelector<number>;
    /**
     * Возвращает общее число записей в источнике данных.
     * @group Selectors
     */
    totalSelector: SimpleSelector<number|undefined>;
    /**
     * Возвращает состояние агрегационных данных.
     * @group Selectors
     */
    aggregationSelector: SimpleSelector<Partial<TModel>>;
    /**
     * Возвращает текущую конфигурацию.
     * @group Selectors
     */
    configSelector: SimpleSelector<EditableApiListState>;
}

/**
 * Режим работы загрузки данных.
 */
export enum ListHandlerMode {
    /**
     * Режим бесконечной последовательной загрузки.
     */
    Continues = 'continues',
    /**
     * Режим постраничной загрузки данных.
     */
    Pages = 'pages',
}

export interface IListHandlerAggregationOption<TModel> {
    field: keyof TModel;
}

export interface IListHandlerOptions<TModel, TMode extends ListHandlerMode = ListHandlerMode.Continues> {
    mode?: TMode;

    defaultLimit?: number;

    debounceDelayInMs?: number;
}

const DEFAULT_OPTIONS: Required<IListHandlerOptions<any>> = {
    mode: ListHandlerMode.Continues,
    defaultLimit: 20,
    debounceDelayInMs: 500,
};

export function buildListQueryProps(paging: IApiListPagingProps, state: EditableApiListState): IApiListQueryProps {
    const requestSort: IApiListSortProps = {
        field: state.sortField,
        dir: state.sortDirection || 'desc',
    };

    const queryProps: IApiListQueryProps = {
        paging,
        sort: requestSort,
        search: state.search,
        filter: state.filter,
        // TODO Fix typings
        aggregation: state.aggregation as ApiListAggregationOptions[],

        resolveUrl: (path: string) => queryString.stringifyUrl({
            url: path,
            query: {
                limit: paging.limit,
                offset: paging.offset,
                sort_field: requestSort.field,
                sort_dir: requestSort.field && requestSort.dir,
                search: state.search,
                filter: state.filter && JSON.stringify(state.filter),
                aggregate: state.aggregation &&
                        state.aggregation.map(item => item.field).join(','),
            },
        }, {
            skipNull: true,
            skipEmptyString: true,
        }),
    };

    return queryProps;
}

export function buildListFilterQueryProps(state: EditableApiListState): IApiListQueryProps {
    const queryProps: IApiListQueryProps = {
        search: state.search,
        filter: state.filter,

        resolveUrl: (path: string) => queryString.stringifyUrl({
            url: path,
            query: {
                search: state.search,
                filter: state.filter && JSON.stringify(state.filter),
            },
        }, {
            skipNull: true,
            skipEmptyString: true,
        }),
    };

    return queryProps;
}

/**
 * Создает хендлер {@link IListApiHandler}.
 * Позволяет обеспечить доступ к табличным данным, поддерживающий частичную загрузку данных, фильтрацию, сортировку, поиск и агрегацию.
 *
 * @param prefix Префикс duck модуля
 * @param key Имя хендлера
 * @param apiMethod Функция для получения данных от АПИ, в качестве параметра передается настройки фильтров {@link IApiListQueryProps}
 * @param apiDataSelector Функция для получения модели данных от сырого АПИ запроса. Если не указано, то берется из поля `data`
 * @param initialStateBuilder Функция для создания начального состояния
 * @param networkOptions Параметры определяющие сетевое поведение хендлера
 * @param handlerOptions Дополнительные параметры хендлера
 * @returns Новый экземпляр хендлера {@link IListApiHandler}, который можно использовать в компонентах и другой логике.
 *
 * @category Duck Handlers
 *
 * @see {@link IListApiHandler}
 * @see {@link useListApiHandler}
 * @see {@link useIsApiFetching}
 * @see {@link simpleApiHandler}
 * @see {@link collectionApiHandler}
 * @see {@link connectRemoteTable}
 *
 * @example
 * // service.ts
 * export async function apiGetPortfolioLoans(query: IApiListQueryProps): Promise<ApiListResponse<PortfolioLoanApiModel>> {
 *      return await apiGetRequest(query.resolveUrl('/portfolio/loans'));
 * }
 *
 * // ducks.ts
 * export const portfolioLoansListHandler = listApiHandler(
 *      PREFIX, 'loans',
 *      apiGetPortfolioLoans
 * );
 *
 * // PortfolioTable.ts
 *
 * const ConnectedPortfolioLoansTable = connectRemoteTable(PortfolioLoansTable, portfolioLoansListHandler);
 */
export function listApiHandler<TModel, TApiResponse = ApiListResponse<TModel>, TMode extends ListHandlerMode = ListHandlerMode.Continues>(
    prefix: string,
    key: string,
    apiMethod: (paging: IApiListQueryProps, ...args) => TApiMethodResponse<ApiListResponse<TModel>|TApiResponse>,
    apiDataSelector: (response: TApiResponse) => TModel[] = (response: any) => response?.data,
    // TODO Put to handlerOptions
    initialStateBuilder?: () => Partial<EditableApiListState> | Partial<EditableApiListState>,
    // TODO Merge with handler options
    networkOptions?: Partial<NetworkOptions>,
    handlerOptions?: IListHandlerOptions<TModel, TMode>
): IListApiHandler<TModel, TMode> {
    const id = `${prefix}/${key}`;
    const actionName = key.toUpperCase();

    const options: Required<IListHandlerOptions<TModel, any>> = {
        ...DEFAULT_OPTIONS,
        ...(handlerOptions || {}),
    };

    // const apiMethodWithLock = withLock(apiMethod as any, args => JSON.stringify(args), {
    //     throwErrorOnReject: true,
    // });

    const FETCH = createType(prefix, `FETCH_${actionName}`);
    const FETCH_DONE = createType(prefix, `FETCH_DONE_${actionName}`);
    const FETCH_NEXT = createType(prefix, `FETCH_NEXT_${actionName}`);
    const FETCH_PAGE = createType(prefix, `FETCH_PAGE_${actionName}`);
    const ALL_DONE = createType(prefix, `ALL_DONE_${actionName}`);
    const UPDATE = createType(prefix, `UPDATE_${actionName}`);
    const UPDATE_CONFIG = createType(prefix, `UPDATE_CONFIG_${actionName}`);

    const fetch = (paging?: IApiListPagingProps) => ({
        type: FETCH,
        paging,
    });
    const fetchUpdate = () => ({
        type: FETCH,
        force: true,
    });
    const fetchNext = createUpdateAction(FETCH_NEXT);
    const fetchPage = (paging: IApiListPagingProps) => ({
        type: FETCH_PAGE,
        paging,
    });
    const fetchDone = (paging: IApiListPagingProps, response) => ({
        type: FETCH_DONE,
        paging,
        response,
    });
    const allDone = createUpdateAction(ALL_DONE);

    const update = createUpdateAction<ApiListState<TModel>>(UPDATE);
    const updateConfig = (config: Partial<EditableApiListState>) => ({
        type: UPDATE_CONFIG,
        config,
    });

    const getDuck = state => state[prefix];
    const getData = createSelector(getDuck, data => data?.[key] as ApiListState<TModel>);

    const selector = createSelector(getData, data => data?.data);
    const totalSelector = createSelector(getData, data => data?.totalCountResponse);
    const pageSelector = createSelector(getData, data => {
        if (data) {
            return Math.floor(data.offset / data.limit);
        }

        return 0;
    });
    const configSelector = createSelector(getData, (data): Omit<EditableApiListState<TModel>, 'limit'|'offset'> => ({
        // TODO Fill other config fields
        search: data?.search,
        filter: data?.filter,
        aggregation: data?.aggregation,
        sortField: data?.sortField,
        sortDirection: data?.sortDirection,
    }));
    const aggregationSelector = createSelector(getData, data => data?.aggregationResponse || {});
    const isFetching = createSelector(getData, data => data?.fetching);
    const isFetchingExtra = createSelector(getData, data => data?.fetchingExtra);
    const isCompleted = createSelector(getData, data => data?.completed);
    const ready = createSelector(getData, data => data?.ready);
    const state = createSelector(getData, data => {
        if (data?.fetching) {
            return 'fetching';
        }

        if (data?.ready) {
            return 'ready';
        }

        return 'new';
    });

    const buildInitialState = (): ApiListState<TModel> => {
        const userInitialState = typeof initialStateBuilder === 'function'
            ? initialStateBuilder() || {}
            : initialStateBuilder || {};

        return {
            data: [],
            fetching: false,
            fetchingExtra: false,
            ready: false,
            completed: false,
            limit: options.defaultLimit,
            offset: 0,

            ...userInitialState,
        };
    };

    const reducer = createUpdateReducer<ApiListState<TModel>>(UPDATE, buildInitialState);

    const reducerInfo = { [key]: reducer };

    function* fetchSaga({
        paging,
        force,
    }) {
        const currentState: ApiListState<TModel> = yield select(getData);

        if (currentState.completed && !force) {
            return;
        }

        const requestPaging: IApiListPagingProps = paging || {
            offset: force
                ? 0
                : currentState.offset || 0,
            limit: currentState.limit || options.defaultLimit,
        };

        const requestSort: IApiListSortProps = {
            field: currentState.sortField,
            dir: currentState.sortDirection || 'desc',
        };

        const apiMethodOptions: IApiListQueryProps = buildListQueryProps(requestPaging, currentState);
        const source = JSON.stringify(apiMethodOptions);

        const isInitialFetching = currentState.data.length === 0 || requestPaging.offset === 0;

        // perform API request

        const networkStat = new NetworkRequestStat();

        yield put(update({
            fetching: isInitialFetching,
            fetchingExtra: !isInitialFetching,
        }));

        try {
            // Используем обычный apiMethod без блокировок, т.к. используем takeLatest для обработки запросов
            const resp: TApiResponse = yield call(callApiSaga, apiMethod, networkOptions?.errorHandler, apiMethodOptions);
            networkStat.finish();

            if (!resp) {
                yield put(update({
                    fetching: false,
                    fetchingExtra: false,
                }));

                logNetworkError(`FETCH LIST ${id} empty response`, { source }, networkStat);
                return;
            }

            const data = apiDataSelector(resp);

            const isCompleted = !data || data?.length === 0 || data?.length < currentState?.limit;

            let newDataValue = data;
            if (options.mode === ListHandlerMode.Continues) {
                if (force) {
                    newDataValue = data;
                } else if (currentState?.data && Array.isArray(currentState?.data)) {
                    if (data && data.length > 0) {
                        newDataValue = [
                            ...currentState.data.slice(0, requestPaging.offset),
                            ...data,
                        ];
                    } else {
                        newDataValue = currentState.data;
                    }
                }
            }

            yield put(update({
                data: newDataValue,
                offset: requestPaging.offset,
                // TODO extract to function like apiDataSelector
                totalCountResponse: (resp as ApiListResponse<TModel>).total,
                // TODO extract to function like apiDataSelector
                aggregationResponse: (resp as ApiListResponse<TModel>).aggregation,

                fetching: false,
                fetchingExtra: false,
                ready: true,
                completed: options.mode === ListHandlerMode.Continues && isCompleted,
            }));

            yield put(fetchDone(requestPaging, resp));

            if (isCompleted) {
                yield put(allDone());
            }
        } catch (e) {
            if (e.name === 'AsyncRejectError') {
                // Just ignore duplicate callback
                return;
            }

            yield put(update({
                fetching: false,
                fetchingExtra: false,
            }));

            yield handleCommonApiErrorsSafe(`FETCH COLLECTION ${id} exception`, { source }, networkStat, e, networkOptions?.errorHandler);
        }
    }

    function* fetchNextSaga() {
        const currentState: ApiListState<TModel> = yield select(getData);

        if (currentState.fetching || currentState.fetchingExtra) {
            return;
        }

        if (currentState.completed) {
            return;
        }

        const limit = currentState.limit || options.defaultLimit;
        const nextPaging: IApiListPagingProps = {
            limit,
            offset: currentState.offset + limit,
        };

        yield call(fetchSaga, {
            paging: nextPaging,
            force: false,
        });
    }

    function* fetchPageSaga({ paging }: { paging: IApiListPagingProps }) {
        if (options.mode !== ListHandlerMode.Pages) {
            console.warn(`${actionName}: fetchPage can not be used when 'mode' option set to "${options.mode}". Use "${ListHandlerMode.Pages}" for that.`);
            return;
        }

        yield call(fetchSaga, {
            paging,
            force: true,
        });
    }

    function* clearStateSaga() {
        yield put(update(undefined));
    }

    function* updateConfigSaga({ config }: { type; config: EditableApiListState }) {
        if (!config) {
            return;
        }

        const currentState: ApiListState<TModel> = yield select(getData);
        if (!currentState) {
            yield put(update(config));
            yield put(fetchUpdate());
            return;
        }

        const result = compareStates(currentState, config);
        if (!result.pagingChanged && !result.limitChanged && !result.stateChanged && !result.aggregationChanged) {
            return;
        }

        yield put(update(config));

        if (result.stateChanged || result.limitChanged) {
            yield put(fetchUpdate());
            return;
        }

        if (options.mode === ListHandlerMode.Pages) {
            if (result.pagingChanged || result.aggregationChanged) {
                const paging = {
                    offset: config.offset,
                    limit: config.limit,
                };

                yield put(fetchPage(paging));
                return;
            }
        }

        yield put(fetchUpdate());
    }

    function compareStates(left: EditableApiListState, right: EditableApiListState) {
        const leftWithoutPaging = extractStateWithoutPaging(left);
        const rightWithoutPaging = extractStateWithoutPaging(right);

        return {
            pagingChanged: left.offset !== right.offset || left.limit !== right.limit,
            limitChanged: left.limit !== right.limit,
            stateChanged: !equals(leftWithoutPaging, rightWithoutPaging),
            aggregationChanged: left.aggregation === right.aggregation && left.aggregation && right.aggregation
                ? !equals(left.aggregation, right.aggregation)
                : false,
        };
    }

    function extractStateWithoutPaging(state: EditableApiListState): Omit<EditableApiListState, 'offset'|'limit'> {
        return {
            filter: state.filter,
            search: state.search,
            sortDirection: state.sortDirection,
            sortField: state.sortField,
        };
    }

    function extractEditableState(state: ApiListState<TModel>): EditableApiListState {
        return {
            offset: state.offset,
            limit: state.limit,
            filter: state.filter,
            search: state.search,
            sortDirection: state.sortDirection,
            sortField: state.sortField,
        };
    }

    const effects = [
        takeLatest(FETCH as any, fetchSaga),
        takeEvery(FETCH_NEXT as any, fetchNextSaga),
        takeLatest(FETCH_PAGE as any, fetchPageSaga),
        takeLatest(CLEAR_FETCH_STATE, clearStateSaga),
        takeLatest(UPDATE_CONFIG, updateConfigSaga),
    ];

    return {
        mode: options.mode,
        FETCH,
        FETCH_DONE,
        FETCH_NEXT,
        FETCH_PAGE,
        ALL_DONE,

        fetch,
        fetchUpdate,
        fetchNext,
        fetchPage,
        updateConfig,
        isCompleted,
        isFetching,
        isFetchingExtra,
        ready,
        state,

        selector,
        pageSelector,
        totalSelector,
        aggregationSelector,
        // TODO Fix typings
        configSelector: configSelector as any,

        reducer,
        reducerInfo,
        effects,
    };
}